export default {
  data: () => ({
    chartExportingOptions: {
      enabled: true,
      buttons: {
        contextButton: {
          align: "left",
          verticalAlign: "bottom",
          menuItems: [
            {
              textKey: "downloadPNG",
              text: "Baixar como PNG",
              onclick: function() {
                this.exportChart();
              },
            },
            {
              separator: true,
            },
            {
              textKey: "downloadCSV",
              text: "Baixar como CSV",
              onclick: function() {
                this.downloadCSV();
              },
            },
            {
              textKey: "downloadXLS",
              text: "Baixar como XLS",
              onclick: function() {
                this.downloadXLS();
              },
            },
          ],
        },
      },
    },
  }),
};
