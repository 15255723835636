import chartExportionOptionsMixin from "./chartExportionOptionsMixin";

export default {
  mixins: [chartExportionOptionsMixin],
  data: () => ({}),
  methods: {
    getColumnChartOptions(remainingOptions) {
      return {
        chart: {
          animation: false,
          type: "column",
          zooming: {
            type: "x",
          },
          height: 400,
        },
        credits: { enabled: false },
        title: { text: null },
        subtitle: { text: null },
        legend: { enabled: false },
        exporting: this.chartExportingOptions,
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
            },
          },
        },
        yAxis: {
          title: { text: null },
        },
        lang: {
          noData: "Sem dados para exibição",
        },
        ...remainingOptions,
      };
    },
  },
};
